.chatContainer {
  background-color: #fff;
  padding: 12px;
  overflow-y: auto;
  height: 92vh;
}

.dateContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 16px;
}

.notesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;
}
.date {
  background: #d9d9d9;
  border-radius: 33px;
  width: fit-content;
  padding: 4px 12px;
  text-align: center;
}
.infoCard,
.addBtn {
  padding: 2px 14px;
  border-radius: 33px;
  font-size: 16px;
  border: none;
  background: #d9d9d9;
  cursor: pointer;
  text-align: center;
}
.infoCard {
  color: #000;
}

.addBtn {
  margin-left: auto;
}
.trashButton {
  background-color: transparent;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addBtn:hover,
.addBtn:active {
  background: #b8b8b8;
}
.selectionBlockContainer {
  padding: 12px 0;
  width: 100%;
  margin: -12px 0;
}
.selectionBlock {
  background-color: lightgrey;
  padding: 12px 16px;
  width: 500px;
  border-radius: 12px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.selectionBlock .btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.selectionBlock button {
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;
  padding: 6px 0px;

  /* width: 100%; */
}
.btnContainer button:first-child {
  /* background-color: white; */
  color: #6d6c6c;
}

.btnContainer button:last-child {
  /* background-color: #d9d9d9; */
  color: #000;
}

.selectionContainer {
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
.selectContainer {
  position: relative;
}
.selectContainer svg {
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  width: 24px;
  height: 24px;
}

.input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 600px) {
  .selectionBlock {
    width: 100%;
  }
}
